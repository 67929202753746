<template>
  <div
    class="message h-100 r-75 px-2 w-100"
    :class="user === 'You' ? 'user-message' : 'bot-message'"
    @click="$emit('onEditMessage', index)"
  >
    <div
      class="name"
      :class="user === 'You' ? 'text-right' : 'text-left'"
      @click="$emit('onEditMessage', index)"
    >
      {{ user }}
    </div>
    <b-form-textarea
      v-if="index !== null && editingMessage === index"
      ref="editMessage"
      class="mb-1 w-100"
      placeholder="Leave empty to remove"
      :value="event.message"
      @input="(event) => $emit('onUpdateMessage', index, $refs.editMessage.vModelValue)"
      @blur="blurLocal()"
      @keyup.enter="$emit('onEditMessageFinished', index)"
    />
    <div
      v-else
      :class="user === 'You' ? 'float-right msg-field' : 'text-left msg-field'"
    >
      {{ event.message }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'EditableMessage',
  props: {
    user: {
      type: String,
      default: null,
    },
    event: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    editingMessage: {
      type: Number,
      default: null,
    },
  },
  watch: {
    editingMessage(newVal) {
      if (newVal === this.index) {
        this.$nextTick(() => this.$refs.editMessage.focus());
      }
    },
  },
  mounted() {
    if (this.index !== null && this.editingMessage === this.index) {
      this.$nextTick(() => this.$refs.editMessage.focus());
    }
  },
  methods: {
    blurLocal() {
      this.$emit('onEditMessageFinished', this.index);
    },
  },
};
</script>
<style scoped>

.message {
  background: rgb(245,245,245);
}
.bot-message {
  margin-right: auto;
}
.user-message {
  margin-left: auto;
}
.msg-field{
  word-wrap: break-word;
}
</style>
